import { default as awardsWC1P1dvWesMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/awards.vue?macro=true";
import { default as certificationsPIf8uSIburMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/certifications.vue?macro=true";
import { default as heritagecJ09ypQaUwMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/heritage.vue?macro=true";
import { default as our_45group8pXQziSbawMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/our-group.vue?macro=true";
import { default as partnershv0EM5nIOrMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/partners.vue?macro=true";
import { default as sustainabilityaeKHl0EaQGMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/sustainability.vue?macro=true";
import { default as teamyDCKtY6U0ZMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/team.vue?macro=true";
import { default as visit_45pininfarinaSu5ZPyWPOFMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/about/visit-pininfarina.vue?macro=true";
import { default as methodologyDFayaJB3JRMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/approach/methodology.vue?macro=true";
import { default as _91jobSlug_93gKFYC6TmpuMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/careers/[jobSlug].vue?macro=true";
import { default as indexd00QQCf0kuMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/careers/index.vue?macro=true";
import { default as index1rHpIeMhRZMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/classiche/index.vue?macro=true";
import { default as _91countrySlug_93kufZX5UafgMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/contacts/[countrySlug].vue?macro=true";
import { default as indexIkHQV7FHJIMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/contacts/index.vue?macro=true";
import { default as messageSentukGSBm9fgEMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/contacts/messageSent.vue?macro=true";
import { default as cookie_45policyzM3fCSaAWyMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/cookie-policy.vue?macro=true";
import { default as indexoJ5PEpskCrMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/index.vue?macro=true";
import { default as indexHo3ZVO0XRaMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue?macro=true";
import { default as hq_45resourcesBCbnPl38gRMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/media-hub/hq-resources.vue?macro=true";
import { default as index7EwFNyemF5Meta } from "/home/forge/pininfarina.quattrolinee.com/pages/media-hub/index.vue?macro=true";
import { default as loginseXfeG2RtKMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/media-hub/login.vue?macro=true";
import { default as _91newsSlug_932cahPYSTuBMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/news/[newsSlug].vue?macro=true";
import { default as indexFjYGdysF1cMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/news/index.vue?macro=true";
import { default as successHdcux76FLCMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/pardot/success.vue?macro=true";
import { default as privacy_45policy5opOjD8sqjMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/privacy-policy.vue?macro=true";
import { default as _91projectSlug_93gGusIpuMccMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/projects/[projectSlug].vue?macro=true";
import { default as indexUcZSuMQNQcMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/search/index.vue?macro=true";
import { default as indexorI57omolOMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue?macro=true";
import { default as portfolioNBJIGvvK1yMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue?macro=true";
import { default as _91serviceSlug_93hjQ8kQ8sDPMeta } from "/home/forge/pininfarina.quattrolinee.com/pages/services/[serviceSlug].vue?macro=true";
import { default as indexmfJZQoGmz8Meta } from "/home/forge/pininfarina.quattrolinee.com/pages/whistleblowing/index.vue?macro=true";
import { default as pininfarina_46quattrolineeQI0myty2PqMeta } from "/home/forge/pininfarina.quattrolinee.com/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: awardsWC1P1dvWesMeta?.name ?? "about-awards___de",
    path: awardsWC1P1dvWesMeta?.path ?? "/de/um/auszeichnungen",
    meta: awardsWC1P1dvWesMeta || {},
    alias: awardsWC1P1dvWesMeta?.alias || [],
    redirect: awardsWC1P1dvWesMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsWC1P1dvWesMeta?.name ?? "about-awards___en___default",
    path: awardsWC1P1dvWesMeta?.path ?? "/about/awards",
    meta: awardsWC1P1dvWesMeta || {},
    alias: awardsWC1P1dvWesMeta?.alias || [],
    redirect: awardsWC1P1dvWesMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsWC1P1dvWesMeta?.name ?? "about-awards___en",
    path: awardsWC1P1dvWesMeta?.path ?? "/en/about/awards",
    meta: awardsWC1P1dvWesMeta || {},
    alias: awardsWC1P1dvWesMeta?.alias || [],
    redirect: awardsWC1P1dvWesMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsWC1P1dvWesMeta?.name ?? "about-awards___it",
    path: awardsWC1P1dvWesMeta?.path ?? "/it/about/premi",
    meta: awardsWC1P1dvWesMeta || {},
    alias: awardsWC1P1dvWesMeta?.alias || [],
    redirect: awardsWC1P1dvWesMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsWC1P1dvWesMeta?.name ?? "about-awards___zh",
    path: awardsWC1P1dvWesMeta?.path ?? "/zh/guanyu/jiangxiang",
    meta: awardsWC1P1dvWesMeta || {},
    alias: awardsWC1P1dvWesMeta?.alias || [],
    redirect: awardsWC1P1dvWesMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: certificationsPIf8uSIburMeta?.name ?? "about-certifications___de",
    path: certificationsPIf8uSIburMeta?.path ?? "/de/about/certifications",
    meta: certificationsPIf8uSIburMeta || {},
    alias: certificationsPIf8uSIburMeta?.alias || [],
    redirect: certificationsPIf8uSIburMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsPIf8uSIburMeta?.name ?? "about-certifications___en___default",
    path: certificationsPIf8uSIburMeta?.path ?? "/about/certifications",
    meta: certificationsPIf8uSIburMeta || {},
    alias: certificationsPIf8uSIburMeta?.alias || [],
    redirect: certificationsPIf8uSIburMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsPIf8uSIburMeta?.name ?? "about-certifications___en",
    path: certificationsPIf8uSIburMeta?.path ?? "/en/about/certifications",
    meta: certificationsPIf8uSIburMeta || {},
    alias: certificationsPIf8uSIburMeta?.alias || [],
    redirect: certificationsPIf8uSIburMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsPIf8uSIburMeta?.name ?? "about-certifications___it",
    path: certificationsPIf8uSIburMeta?.path ?? "/it/about/certifications",
    meta: certificationsPIf8uSIburMeta || {},
    alias: certificationsPIf8uSIburMeta?.alias || [],
    redirect: certificationsPIf8uSIburMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsPIf8uSIburMeta?.name ?? "about-certifications___zh",
    path: certificationsPIf8uSIburMeta?.path ?? "/zh/about/certifications",
    meta: certificationsPIf8uSIburMeta || {},
    alias: certificationsPIf8uSIburMeta?.alias || [],
    redirect: certificationsPIf8uSIburMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: heritagecJ09ypQaUwMeta?.name ?? "about-heritage___de",
    path: heritagecJ09ypQaUwMeta?.path ?? "/de/um/erbe",
    meta: heritagecJ09ypQaUwMeta || {},
    alias: heritagecJ09ypQaUwMeta?.alias || [],
    redirect: heritagecJ09ypQaUwMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagecJ09ypQaUwMeta?.name ?? "about-heritage___en___default",
    path: heritagecJ09ypQaUwMeta?.path ?? "/about/heritage",
    meta: heritagecJ09ypQaUwMeta || {},
    alias: heritagecJ09ypQaUwMeta?.alias || [],
    redirect: heritagecJ09ypQaUwMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagecJ09ypQaUwMeta?.name ?? "about-heritage___en",
    path: heritagecJ09ypQaUwMeta?.path ?? "/en/about/heritage",
    meta: heritagecJ09ypQaUwMeta || {},
    alias: heritagecJ09ypQaUwMeta?.alias || [],
    redirect: heritagecJ09ypQaUwMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagecJ09ypQaUwMeta?.name ?? "about-heritage___it",
    path: heritagecJ09ypQaUwMeta?.path ?? "/it/about/heritage",
    meta: heritagecJ09ypQaUwMeta || {},
    alias: heritagecJ09ypQaUwMeta?.alias || [],
    redirect: heritagecJ09ypQaUwMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagecJ09ypQaUwMeta?.name ?? "about-heritage___zh",
    path: heritagecJ09ypQaUwMeta?.path ?? "/zh/guanyu/yichan",
    meta: heritagecJ09ypQaUwMeta || {},
    alias: heritagecJ09ypQaUwMeta?.alias || [],
    redirect: heritagecJ09ypQaUwMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: our_45group8pXQziSbawMeta?.name ?? "about-our-group___de",
    path: our_45group8pXQziSbawMeta?.path ?? "/de/um/unsere-gruppe",
    meta: our_45group8pXQziSbawMeta || {},
    alias: our_45group8pXQziSbawMeta?.alias || [],
    redirect: our_45group8pXQziSbawMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45group8pXQziSbawMeta?.name ?? "about-our-group___en___default",
    path: our_45group8pXQziSbawMeta?.path ?? "/about/our-group",
    meta: our_45group8pXQziSbawMeta || {},
    alias: our_45group8pXQziSbawMeta?.alias || [],
    redirect: our_45group8pXQziSbawMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45group8pXQziSbawMeta?.name ?? "about-our-group___en",
    path: our_45group8pXQziSbawMeta?.path ?? "/en/about/our-group",
    meta: our_45group8pXQziSbawMeta || {},
    alias: our_45group8pXQziSbawMeta?.alias || [],
    redirect: our_45group8pXQziSbawMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45group8pXQziSbawMeta?.name ?? "about-our-group___it",
    path: our_45group8pXQziSbawMeta?.path ?? "/it/about/il-gruppo",
    meta: our_45group8pXQziSbawMeta || {},
    alias: our_45group8pXQziSbawMeta?.alias || [],
    redirect: our_45group8pXQziSbawMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45group8pXQziSbawMeta?.name ?? "about-our-group___zh",
    path: our_45group8pXQziSbawMeta?.path ?? "/zh/guanyu/women-de-zu",
    meta: our_45group8pXQziSbawMeta || {},
    alias: our_45group8pXQziSbawMeta?.alias || [],
    redirect: our_45group8pXQziSbawMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: partnershv0EM5nIOrMeta?.name ?? "about-partners___de",
    path: partnershv0EM5nIOrMeta?.path ?? "/de/about/partners",
    meta: partnershv0EM5nIOrMeta || {},
    alias: partnershv0EM5nIOrMeta?.alias || [],
    redirect: partnershv0EM5nIOrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnershv0EM5nIOrMeta?.name ?? "about-partners___en___default",
    path: partnershv0EM5nIOrMeta?.path ?? "/about/partners",
    meta: partnershv0EM5nIOrMeta || {},
    alias: partnershv0EM5nIOrMeta?.alias || [],
    redirect: partnershv0EM5nIOrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnershv0EM5nIOrMeta?.name ?? "about-partners___en",
    path: partnershv0EM5nIOrMeta?.path ?? "/en/about/partners",
    meta: partnershv0EM5nIOrMeta || {},
    alias: partnershv0EM5nIOrMeta?.alias || [],
    redirect: partnershv0EM5nIOrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnershv0EM5nIOrMeta?.name ?? "about-partners___it",
    path: partnershv0EM5nIOrMeta?.path ?? "/it/about/partners",
    meta: partnershv0EM5nIOrMeta || {},
    alias: partnershv0EM5nIOrMeta?.alias || [],
    redirect: partnershv0EM5nIOrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnershv0EM5nIOrMeta?.name ?? "about-partners___zh",
    path: partnershv0EM5nIOrMeta?.path ?? "/zh/about/partners",
    meta: partnershv0EM5nIOrMeta || {},
    alias: partnershv0EM5nIOrMeta?.alias || [],
    redirect: partnershv0EM5nIOrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityaeKHl0EaQGMeta?.name ?? "about-sustainability___de",
    path: sustainabilityaeKHl0EaQGMeta?.path ?? "/de/um/nachhaltigkeit",
    meta: sustainabilityaeKHl0EaQGMeta || {},
    alias: sustainabilityaeKHl0EaQGMeta?.alias || [],
    redirect: sustainabilityaeKHl0EaQGMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityaeKHl0EaQGMeta?.name ?? "about-sustainability___en___default",
    path: sustainabilityaeKHl0EaQGMeta?.path ?? "/about/sustainability",
    meta: sustainabilityaeKHl0EaQGMeta || {},
    alias: sustainabilityaeKHl0EaQGMeta?.alias || [],
    redirect: sustainabilityaeKHl0EaQGMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityaeKHl0EaQGMeta?.name ?? "about-sustainability___en",
    path: sustainabilityaeKHl0EaQGMeta?.path ?? "/en/about/sustainability",
    meta: sustainabilityaeKHl0EaQGMeta || {},
    alias: sustainabilityaeKHl0EaQGMeta?.alias || [],
    redirect: sustainabilityaeKHl0EaQGMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityaeKHl0EaQGMeta?.name ?? "about-sustainability___it",
    path: sustainabilityaeKHl0EaQGMeta?.path ?? "/it/about/sostenibilita",
    meta: sustainabilityaeKHl0EaQGMeta || {},
    alias: sustainabilityaeKHl0EaQGMeta?.alias || [],
    redirect: sustainabilityaeKHl0EaQGMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityaeKHl0EaQGMeta?.name ?? "about-sustainability___zh",
    path: sustainabilityaeKHl0EaQGMeta?.path ?? "/zh/guanyu/ke-chixu-xing",
    meta: sustainabilityaeKHl0EaQGMeta || {},
    alias: sustainabilityaeKHl0EaQGMeta?.alias || [],
    redirect: sustainabilityaeKHl0EaQGMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: teamyDCKtY6U0ZMeta?.name ?? "about-team___de",
    path: teamyDCKtY6U0ZMeta?.path ?? "/de/um/team",
    meta: teamyDCKtY6U0ZMeta || {},
    alias: teamyDCKtY6U0ZMeta?.alias || [],
    redirect: teamyDCKtY6U0ZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamyDCKtY6U0ZMeta?.name ?? "about-team___en___default",
    path: teamyDCKtY6U0ZMeta?.path ?? "/about/team",
    meta: teamyDCKtY6U0ZMeta || {},
    alias: teamyDCKtY6U0ZMeta?.alias || [],
    redirect: teamyDCKtY6U0ZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamyDCKtY6U0ZMeta?.name ?? "about-team___en",
    path: teamyDCKtY6U0ZMeta?.path ?? "/en/about/team",
    meta: teamyDCKtY6U0ZMeta || {},
    alias: teamyDCKtY6U0ZMeta?.alias || [],
    redirect: teamyDCKtY6U0ZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamyDCKtY6U0ZMeta?.name ?? "about-team___it",
    path: teamyDCKtY6U0ZMeta?.path ?? "/it/about/team",
    meta: teamyDCKtY6U0ZMeta || {},
    alias: teamyDCKtY6U0ZMeta?.alias || [],
    redirect: teamyDCKtY6U0ZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamyDCKtY6U0ZMeta?.name ?? "about-team___zh",
    path: teamyDCKtY6U0ZMeta?.path ?? "/zh/guanyu/tuandui",
    meta: teamyDCKtY6U0ZMeta || {},
    alias: teamyDCKtY6U0ZMeta?.alias || [],
    redirect: teamyDCKtY6U0ZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaSu5ZPyWPOFMeta?.name ?? "about-visit-pininfarina___de",
    path: visit_45pininfarinaSu5ZPyWPOFMeta?.path ?? "/de/um/besuchen-sie-pininfarina",
    meta: visit_45pininfarinaSu5ZPyWPOFMeta || {},
    alias: visit_45pininfarinaSu5ZPyWPOFMeta?.alias || [],
    redirect: visit_45pininfarinaSu5ZPyWPOFMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaSu5ZPyWPOFMeta?.name ?? "about-visit-pininfarina___en___default",
    path: visit_45pininfarinaSu5ZPyWPOFMeta?.path ?? "/about/visit-pininfarina",
    meta: visit_45pininfarinaSu5ZPyWPOFMeta || {},
    alias: visit_45pininfarinaSu5ZPyWPOFMeta?.alias || [],
    redirect: visit_45pininfarinaSu5ZPyWPOFMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaSu5ZPyWPOFMeta?.name ?? "about-visit-pininfarina___en",
    path: visit_45pininfarinaSu5ZPyWPOFMeta?.path ?? "/en/about/visit-pininfarina",
    meta: visit_45pininfarinaSu5ZPyWPOFMeta || {},
    alias: visit_45pininfarinaSu5ZPyWPOFMeta?.alias || [],
    redirect: visit_45pininfarinaSu5ZPyWPOFMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaSu5ZPyWPOFMeta?.name ?? "about-visit-pininfarina___it",
    path: visit_45pininfarinaSu5ZPyWPOFMeta?.path ?? "/it/about/visita-pininfarina",
    meta: visit_45pininfarinaSu5ZPyWPOFMeta || {},
    alias: visit_45pininfarinaSu5ZPyWPOFMeta?.alias || [],
    redirect: visit_45pininfarinaSu5ZPyWPOFMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaSu5ZPyWPOFMeta?.name ?? "about-visit-pininfarina___zh",
    path: visit_45pininfarinaSu5ZPyWPOFMeta?.path ?? "/zh/guanyu/hui-pininfarina",
    meta: visit_45pininfarinaSu5ZPyWPOFMeta || {},
    alias: visit_45pininfarinaSu5ZPyWPOFMeta?.alias || [],
    redirect: visit_45pininfarinaSu5ZPyWPOFMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: methodologyDFayaJB3JRMeta?.name ?? "approach-methodology___de",
    path: methodologyDFayaJB3JRMeta?.path ?? "/de/approach/methodology",
    meta: methodologyDFayaJB3JRMeta || {},
    alias: methodologyDFayaJB3JRMeta?.alias || [],
    redirect: methodologyDFayaJB3JRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyDFayaJB3JRMeta?.name ?? "approach-methodology___en___default",
    path: methodologyDFayaJB3JRMeta?.path ?? "/approach/methodology",
    meta: methodologyDFayaJB3JRMeta || {},
    alias: methodologyDFayaJB3JRMeta?.alias || [],
    redirect: methodologyDFayaJB3JRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyDFayaJB3JRMeta?.name ?? "approach-methodology___en",
    path: methodologyDFayaJB3JRMeta?.path ?? "/en/approach/methodology",
    meta: methodologyDFayaJB3JRMeta || {},
    alias: methodologyDFayaJB3JRMeta?.alias || [],
    redirect: methodologyDFayaJB3JRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyDFayaJB3JRMeta?.name ?? "approach-methodology___it",
    path: methodologyDFayaJB3JRMeta?.path ?? "/it/approach/methodology",
    meta: methodologyDFayaJB3JRMeta || {},
    alias: methodologyDFayaJB3JRMeta?.alias || [],
    redirect: methodologyDFayaJB3JRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyDFayaJB3JRMeta?.name ?? "approach-methodology___zh",
    path: methodologyDFayaJB3JRMeta?.path ?? "/zh/approach/methodology",
    meta: methodologyDFayaJB3JRMeta || {},
    alias: methodologyDFayaJB3JRMeta?.alias || [],
    redirect: methodologyDFayaJB3JRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93gKFYC6TmpuMeta?.name ?? "careers-jobSlug___de",
    path: _91jobSlug_93gKFYC6TmpuMeta?.path ?? "/de/karriere/:jobSlug",
    meta: _91jobSlug_93gKFYC6TmpuMeta || {},
    alias: _91jobSlug_93gKFYC6TmpuMeta?.alias || [],
    redirect: _91jobSlug_93gKFYC6TmpuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93gKFYC6TmpuMeta?.name ?? "careers-jobSlug___en___default",
    path: _91jobSlug_93gKFYC6TmpuMeta?.path ?? "/careers/:jobSlug",
    meta: _91jobSlug_93gKFYC6TmpuMeta || {},
    alias: _91jobSlug_93gKFYC6TmpuMeta?.alias || [],
    redirect: _91jobSlug_93gKFYC6TmpuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93gKFYC6TmpuMeta?.name ?? "careers-jobSlug___en",
    path: _91jobSlug_93gKFYC6TmpuMeta?.path ?? "/en/careers/:jobSlug",
    meta: _91jobSlug_93gKFYC6TmpuMeta || {},
    alias: _91jobSlug_93gKFYC6TmpuMeta?.alias || [],
    redirect: _91jobSlug_93gKFYC6TmpuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93gKFYC6TmpuMeta?.name ?? "careers-jobSlug___it",
    path: _91jobSlug_93gKFYC6TmpuMeta?.path ?? "/it/carriere/:jobSlug",
    meta: _91jobSlug_93gKFYC6TmpuMeta || {},
    alias: _91jobSlug_93gKFYC6TmpuMeta?.alias || [],
    redirect: _91jobSlug_93gKFYC6TmpuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93gKFYC6TmpuMeta?.name ?? "careers-jobSlug___zh",
    path: _91jobSlug_93gKFYC6TmpuMeta?.path ?? "/zh/zhiye-shengya/:jobSlug",
    meta: _91jobSlug_93gKFYC6TmpuMeta || {},
    alias: _91jobSlug_93gKFYC6TmpuMeta?.alias || [],
    redirect: _91jobSlug_93gKFYC6TmpuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: indexd00QQCf0kuMeta?.name ?? "careers___de",
    path: indexd00QQCf0kuMeta?.path ?? "/de/karriere",
    meta: indexd00QQCf0kuMeta || {},
    alias: indexd00QQCf0kuMeta?.alias || [],
    redirect: indexd00QQCf0kuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexd00QQCf0kuMeta?.name ?? "careers___en___default",
    path: indexd00QQCf0kuMeta?.path ?? "/careers/",
    meta: indexd00QQCf0kuMeta || {},
    alias: indexd00QQCf0kuMeta?.alias || [],
    redirect: indexd00QQCf0kuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexd00QQCf0kuMeta?.name ?? "careers___en",
    path: indexd00QQCf0kuMeta?.path ?? "/en/careers",
    meta: indexd00QQCf0kuMeta || {},
    alias: indexd00QQCf0kuMeta?.alias || [],
    redirect: indexd00QQCf0kuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexd00QQCf0kuMeta?.name ?? "careers___it",
    path: indexd00QQCf0kuMeta?.path ?? "/it/carriere",
    meta: indexd00QQCf0kuMeta || {},
    alias: indexd00QQCf0kuMeta?.alias || [],
    redirect: indexd00QQCf0kuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexd00QQCf0kuMeta?.name ?? "careers___zh",
    path: indexd00QQCf0kuMeta?.path ?? "/zh/zhiye-shengya",
    meta: indexd00QQCf0kuMeta || {},
    alias: indexd00QQCf0kuMeta?.alias || [],
    redirect: indexd00QQCf0kuMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index1rHpIeMhRZMeta?.name ?? "classiche___de",
    path: index1rHpIeMhRZMeta?.path ?? "/de/classiche",
    meta: index1rHpIeMhRZMeta || {},
    alias: index1rHpIeMhRZMeta?.alias || [],
    redirect: index1rHpIeMhRZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: index1rHpIeMhRZMeta?.name ?? "classiche___en___default",
    path: index1rHpIeMhRZMeta?.path ?? "/classiche/",
    meta: index1rHpIeMhRZMeta || {},
    alias: index1rHpIeMhRZMeta?.alias || [],
    redirect: index1rHpIeMhRZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: index1rHpIeMhRZMeta?.name ?? "classiche___en",
    path: index1rHpIeMhRZMeta?.path ?? "/en/classiche",
    meta: index1rHpIeMhRZMeta || {},
    alias: index1rHpIeMhRZMeta?.alias || [],
    redirect: index1rHpIeMhRZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: index1rHpIeMhRZMeta?.name ?? "classiche___it",
    path: index1rHpIeMhRZMeta?.path ?? "/it/classiche",
    meta: index1rHpIeMhRZMeta || {},
    alias: index1rHpIeMhRZMeta?.alias || [],
    redirect: index1rHpIeMhRZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: index1rHpIeMhRZMeta?.name ?? "classiche___zh",
    path: index1rHpIeMhRZMeta?.path ?? "/zh/classiche",
    meta: index1rHpIeMhRZMeta || {},
    alias: index1rHpIeMhRZMeta?.alias || [],
    redirect: index1rHpIeMhRZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93kufZX5UafgMeta?.name ?? "contacts-countrySlug___de",
    path: _91countrySlug_93kufZX5UafgMeta?.path ?? "/de/kontakte/:countrySlug",
    meta: _91countrySlug_93kufZX5UafgMeta || {},
    alias: _91countrySlug_93kufZX5UafgMeta?.alias || [],
    redirect: _91countrySlug_93kufZX5UafgMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93kufZX5UafgMeta?.name ?? "contacts-countrySlug___en___default",
    path: _91countrySlug_93kufZX5UafgMeta?.path ?? "/contacts/:countrySlug",
    meta: _91countrySlug_93kufZX5UafgMeta || {},
    alias: _91countrySlug_93kufZX5UafgMeta?.alias || [],
    redirect: _91countrySlug_93kufZX5UafgMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93kufZX5UafgMeta?.name ?? "contacts-countrySlug___en",
    path: _91countrySlug_93kufZX5UafgMeta?.path ?? "/en/contacts/:countrySlug",
    meta: _91countrySlug_93kufZX5UafgMeta || {},
    alias: _91countrySlug_93kufZX5UafgMeta?.alias || [],
    redirect: _91countrySlug_93kufZX5UafgMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93kufZX5UafgMeta?.name ?? "contacts-countrySlug___it",
    path: _91countrySlug_93kufZX5UafgMeta?.path ?? "/it/contatti/:countrySlug",
    meta: _91countrySlug_93kufZX5UafgMeta || {},
    alias: _91countrySlug_93kufZX5UafgMeta?.alias || [],
    redirect: _91countrySlug_93kufZX5UafgMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93kufZX5UafgMeta?.name ?? "contacts-countrySlug___zh",
    path: _91countrySlug_93kufZX5UafgMeta?.path ?? "/zh/lianxi-ren/:countrySlug",
    meta: _91countrySlug_93kufZX5UafgMeta || {},
    alias: _91countrySlug_93kufZX5UafgMeta?.alias || [],
    redirect: _91countrySlug_93kufZX5UafgMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: indexIkHQV7FHJIMeta?.name ?? "contacts___de",
    path: indexIkHQV7FHJIMeta?.path ?? "/de/kontakte/unsere-gruppe",
    meta: indexIkHQV7FHJIMeta || {},
    alias: indexIkHQV7FHJIMeta?.alias || [],
    redirect: indexIkHQV7FHJIMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexIkHQV7FHJIMeta?.name ?? "contacts___en___default",
    path: indexIkHQV7FHJIMeta?.path ?? "/contacts/our-offices",
    meta: indexIkHQV7FHJIMeta || {},
    alias: indexIkHQV7FHJIMeta?.alias || [],
    redirect: indexIkHQV7FHJIMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexIkHQV7FHJIMeta?.name ?? "contacts___en",
    path: indexIkHQV7FHJIMeta?.path ?? "/en/contacts/our-offices",
    meta: indexIkHQV7FHJIMeta || {},
    alias: indexIkHQV7FHJIMeta?.alias || [],
    redirect: indexIkHQV7FHJIMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexIkHQV7FHJIMeta?.name ?? "contacts___it",
    path: indexIkHQV7FHJIMeta?.path ?? "/it/contatti/i-nostri-uffici",
    meta: indexIkHQV7FHJIMeta || {},
    alias: indexIkHQV7FHJIMeta?.alias || [],
    redirect: indexIkHQV7FHJIMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexIkHQV7FHJIMeta?.name ?? "contacts___zh",
    path: indexIkHQV7FHJIMeta?.path ?? "/zh/lianxi-ren/women-de-zu",
    meta: indexIkHQV7FHJIMeta || {},
    alias: indexIkHQV7FHJIMeta?.alias || [],
    redirect: indexIkHQV7FHJIMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: messageSentukGSBm9fgEMeta?.name ?? "contacts-messageSent___de",
    path: messageSentukGSBm9fgEMeta?.path ?? "/de/message-sent",
    meta: messageSentukGSBm9fgEMeta || {},
    alias: messageSentukGSBm9fgEMeta?.alias || [],
    redirect: messageSentukGSBm9fgEMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentukGSBm9fgEMeta?.name ?? "contacts-messageSent___en___default",
    path: messageSentukGSBm9fgEMeta?.path ?? "/message-sent",
    meta: messageSentukGSBm9fgEMeta || {},
    alias: messageSentukGSBm9fgEMeta?.alias || [],
    redirect: messageSentukGSBm9fgEMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentukGSBm9fgEMeta?.name ?? "contacts-messageSent___en",
    path: messageSentukGSBm9fgEMeta?.path ?? "/en/message-sent",
    meta: messageSentukGSBm9fgEMeta || {},
    alias: messageSentukGSBm9fgEMeta?.alias || [],
    redirect: messageSentukGSBm9fgEMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentukGSBm9fgEMeta?.name ?? "contacts-messageSent___it",
    path: messageSentukGSBm9fgEMeta?.path ?? "/it/message-sent",
    meta: messageSentukGSBm9fgEMeta || {},
    alias: messageSentukGSBm9fgEMeta?.alias || [],
    redirect: messageSentukGSBm9fgEMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentukGSBm9fgEMeta?.name ?? "contacts-messageSent___zh",
    path: messageSentukGSBm9fgEMeta?.path ?? "/zh/message-sent",
    meta: messageSentukGSBm9fgEMeta || {},
    alias: messageSentukGSBm9fgEMeta?.alias || [],
    redirect: messageSentukGSBm9fgEMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzM3fCSaAWyMeta?.name ?? "cookie-policy___de",
    path: cookie_45policyzM3fCSaAWyMeta?.path ?? "/de/cookie-policy",
    meta: cookie_45policyzM3fCSaAWyMeta || {},
    alias: cookie_45policyzM3fCSaAWyMeta?.alias || [],
    redirect: cookie_45policyzM3fCSaAWyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzM3fCSaAWyMeta?.name ?? "cookie-policy___en___default",
    path: cookie_45policyzM3fCSaAWyMeta?.path ?? "/cookie-policy/",
    meta: cookie_45policyzM3fCSaAWyMeta || {},
    alias: cookie_45policyzM3fCSaAWyMeta?.alias || [],
    redirect: cookie_45policyzM3fCSaAWyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzM3fCSaAWyMeta?.name ?? "cookie-policy___en",
    path: cookie_45policyzM3fCSaAWyMeta?.path ?? "/en/cookie-policy",
    meta: cookie_45policyzM3fCSaAWyMeta || {},
    alias: cookie_45policyzM3fCSaAWyMeta?.alias || [],
    redirect: cookie_45policyzM3fCSaAWyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzM3fCSaAWyMeta?.name ?? "cookie-policy___it",
    path: cookie_45policyzM3fCSaAWyMeta?.path ?? "/it/cookie-policy",
    meta: cookie_45policyzM3fCSaAWyMeta || {},
    alias: cookie_45policyzM3fCSaAWyMeta?.alias || [],
    redirect: cookie_45policyzM3fCSaAWyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzM3fCSaAWyMeta?.name ?? "cookie-policy___zh",
    path: cookie_45policyzM3fCSaAWyMeta?.path ?? "/zh/cookie-policy",
    meta: cookie_45policyzM3fCSaAWyMeta || {},
    alias: cookie_45policyzM3fCSaAWyMeta?.alias || [],
    redirect: cookie_45policyzM3fCSaAWyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexoJ5PEpskCrMeta?.name ?? "index___de",
    path: indexoJ5PEpskCrMeta?.path ?? "/de",
    meta: indexoJ5PEpskCrMeta || {},
    alias: indexoJ5PEpskCrMeta?.alias || [],
    redirect: indexoJ5PEpskCrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoJ5PEpskCrMeta?.name ?? "index___en___default",
    path: indexoJ5PEpskCrMeta?.path ?? "/",
    meta: indexoJ5PEpskCrMeta || {},
    alias: indexoJ5PEpskCrMeta?.alias || [],
    redirect: indexoJ5PEpskCrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoJ5PEpskCrMeta?.name ?? "index___en",
    path: indexoJ5PEpskCrMeta?.path ?? "/en",
    meta: indexoJ5PEpskCrMeta || {},
    alias: indexoJ5PEpskCrMeta?.alias || [],
    redirect: indexoJ5PEpskCrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoJ5PEpskCrMeta?.name ?? "index___it",
    path: indexoJ5PEpskCrMeta?.path ?? "/it",
    meta: indexoJ5PEpskCrMeta || {},
    alias: indexoJ5PEpskCrMeta?.alias || [],
    redirect: indexoJ5PEpskCrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoJ5PEpskCrMeta?.name ?? "index___zh",
    path: indexoJ5PEpskCrMeta?.path ?? "/zh",
    meta: indexoJ5PEpskCrMeta || {},
    alias: indexoJ5PEpskCrMeta?.alias || [],
    redirect: indexoJ5PEpskCrMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHo3ZVO0XRaMeta?.name ?? "investor-relations-investorSlug___de",
    path: indexHo3ZVO0XRaMeta?.path ?? "/de/investor-relations/:investorSlug?",
    meta: indexHo3ZVO0XRaMeta || {},
    alias: indexHo3ZVO0XRaMeta?.alias || [],
    redirect: indexHo3ZVO0XRaMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHo3ZVO0XRaMeta?.name ?? "investor-relations-investorSlug___en___default",
    path: indexHo3ZVO0XRaMeta?.path ?? "/investor-relations/:investorSlug?",
    meta: indexHo3ZVO0XRaMeta || {},
    alias: indexHo3ZVO0XRaMeta?.alias || [],
    redirect: indexHo3ZVO0XRaMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHo3ZVO0XRaMeta?.name ?? "investor-relations-investorSlug___en",
    path: indexHo3ZVO0XRaMeta?.path ?? "/en/investor-relations/:investorSlug?",
    meta: indexHo3ZVO0XRaMeta || {},
    alias: indexHo3ZVO0XRaMeta?.alias || [],
    redirect: indexHo3ZVO0XRaMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHo3ZVO0XRaMeta?.name ?? "investor-relations-investorSlug___it",
    path: indexHo3ZVO0XRaMeta?.path ?? "/it/investor-relations/:investorSlug?",
    meta: indexHo3ZVO0XRaMeta || {},
    alias: indexHo3ZVO0XRaMeta?.alias || [],
    redirect: indexHo3ZVO0XRaMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHo3ZVO0XRaMeta?.name ?? "investor-relations-investorSlug___zh",
    path: indexHo3ZVO0XRaMeta?.path ?? "/zh/investor-relations/:investorSlug?",
    meta: indexHo3ZVO0XRaMeta || {},
    alias: indexHo3ZVO0XRaMeta?.alias || [],
    redirect: indexHo3ZVO0XRaMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesBCbnPl38gRMeta?.name ?? "media-hub-hq-resources___de",
    path: hq_45resourcesBCbnPl38gRMeta?.path ?? "/de/media-hub/hq-resources",
    meta: hq_45resourcesBCbnPl38gRMeta || {},
    alias: hq_45resourcesBCbnPl38gRMeta?.alias || [],
    redirect: hq_45resourcesBCbnPl38gRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesBCbnPl38gRMeta?.name ?? "media-hub-hq-resources___en___default",
    path: hq_45resourcesBCbnPl38gRMeta?.path ?? "/media-hub/hq-resources",
    meta: hq_45resourcesBCbnPl38gRMeta || {},
    alias: hq_45resourcesBCbnPl38gRMeta?.alias || [],
    redirect: hq_45resourcesBCbnPl38gRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesBCbnPl38gRMeta?.name ?? "media-hub-hq-resources___en",
    path: hq_45resourcesBCbnPl38gRMeta?.path ?? "/en/media-hub/hq-resources",
    meta: hq_45resourcesBCbnPl38gRMeta || {},
    alias: hq_45resourcesBCbnPl38gRMeta?.alias || [],
    redirect: hq_45resourcesBCbnPl38gRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesBCbnPl38gRMeta?.name ?? "media-hub-hq-resources___it",
    path: hq_45resourcesBCbnPl38gRMeta?.path ?? "/it/media-hub/hq-resources",
    meta: hq_45resourcesBCbnPl38gRMeta || {},
    alias: hq_45resourcesBCbnPl38gRMeta?.alias || [],
    redirect: hq_45resourcesBCbnPl38gRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesBCbnPl38gRMeta?.name ?? "media-hub-hq-resources___zh",
    path: hq_45resourcesBCbnPl38gRMeta?.path ?? "/zh/media-hub/hq-resources",
    meta: hq_45resourcesBCbnPl38gRMeta || {},
    alias: hq_45resourcesBCbnPl38gRMeta?.alias || [],
    redirect: hq_45resourcesBCbnPl38gRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: index7EwFNyemF5Meta?.name ?? "media-hub___de",
    path: index7EwFNyemF5Meta?.path ?? "/de/media-hub",
    meta: index7EwFNyemF5Meta || {},
    alias: index7EwFNyemF5Meta?.alias || [],
    redirect: index7EwFNyemF5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: index7EwFNyemF5Meta?.name ?? "media-hub___en___default",
    path: index7EwFNyemF5Meta?.path ?? "/media-hub/",
    meta: index7EwFNyemF5Meta || {},
    alias: index7EwFNyemF5Meta?.alias || [],
    redirect: index7EwFNyemF5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: index7EwFNyemF5Meta?.name ?? "media-hub___en",
    path: index7EwFNyemF5Meta?.path ?? "/en/media-hub",
    meta: index7EwFNyemF5Meta || {},
    alias: index7EwFNyemF5Meta?.alias || [],
    redirect: index7EwFNyemF5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: index7EwFNyemF5Meta?.name ?? "media-hub___it",
    path: index7EwFNyemF5Meta?.path ?? "/it/media-hub",
    meta: index7EwFNyemF5Meta || {},
    alias: index7EwFNyemF5Meta?.alias || [],
    redirect: index7EwFNyemF5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: index7EwFNyemF5Meta?.name ?? "media-hub___zh",
    path: index7EwFNyemF5Meta?.path ?? "/zh/media-hub",
    meta: index7EwFNyemF5Meta || {},
    alias: index7EwFNyemF5Meta?.alias || [],
    redirect: index7EwFNyemF5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: loginseXfeG2RtKMeta?.name ?? "media-hub-login___de",
    path: loginseXfeG2RtKMeta?.path ?? "/de/media-hub/login",
    meta: loginseXfeG2RtKMeta || {},
    alias: loginseXfeG2RtKMeta?.alias || [],
    redirect: loginseXfeG2RtKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginseXfeG2RtKMeta?.name ?? "media-hub-login___en___default",
    path: loginseXfeG2RtKMeta?.path ?? "/media-hub/login",
    meta: loginseXfeG2RtKMeta || {},
    alias: loginseXfeG2RtKMeta?.alias || [],
    redirect: loginseXfeG2RtKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginseXfeG2RtKMeta?.name ?? "media-hub-login___en",
    path: loginseXfeG2RtKMeta?.path ?? "/en/media-hub/login",
    meta: loginseXfeG2RtKMeta || {},
    alias: loginseXfeG2RtKMeta?.alias || [],
    redirect: loginseXfeG2RtKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginseXfeG2RtKMeta?.name ?? "media-hub-login___it",
    path: loginseXfeG2RtKMeta?.path ?? "/it/media-hub/login",
    meta: loginseXfeG2RtKMeta || {},
    alias: loginseXfeG2RtKMeta?.alias || [],
    redirect: loginseXfeG2RtKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginseXfeG2RtKMeta?.name ?? "media-hub-login___zh",
    path: loginseXfeG2RtKMeta?.path ?? "/zh/media-hub/login",
    meta: loginseXfeG2RtKMeta || {},
    alias: loginseXfeG2RtKMeta?.alias || [],
    redirect: loginseXfeG2RtKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_932cahPYSTuBMeta?.name ?? "news-newsSlug___de",
    path: _91newsSlug_932cahPYSTuBMeta?.path ?? "/de/nachrichten/:newsSlug",
    meta: _91newsSlug_932cahPYSTuBMeta || {},
    alias: _91newsSlug_932cahPYSTuBMeta?.alias || [],
    redirect: _91newsSlug_932cahPYSTuBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_932cahPYSTuBMeta?.name ?? "news-newsSlug___en___default",
    path: _91newsSlug_932cahPYSTuBMeta?.path ?? "/news/:newsSlug",
    meta: _91newsSlug_932cahPYSTuBMeta || {},
    alias: _91newsSlug_932cahPYSTuBMeta?.alias || [],
    redirect: _91newsSlug_932cahPYSTuBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_932cahPYSTuBMeta?.name ?? "news-newsSlug___en",
    path: _91newsSlug_932cahPYSTuBMeta?.path ?? "/en/news/:newsSlug",
    meta: _91newsSlug_932cahPYSTuBMeta || {},
    alias: _91newsSlug_932cahPYSTuBMeta?.alias || [],
    redirect: _91newsSlug_932cahPYSTuBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_932cahPYSTuBMeta?.name ?? "news-newsSlug___it",
    path: _91newsSlug_932cahPYSTuBMeta?.path ?? "/it/notizie/:newsSlug",
    meta: _91newsSlug_932cahPYSTuBMeta || {},
    alias: _91newsSlug_932cahPYSTuBMeta?.alias || [],
    redirect: _91newsSlug_932cahPYSTuBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_932cahPYSTuBMeta?.name ?? "news-newsSlug___zh",
    path: _91newsSlug_932cahPYSTuBMeta?.path ?? "/zh/xinwen/:newsSlug",
    meta: _91newsSlug_932cahPYSTuBMeta || {},
    alias: _91newsSlug_932cahPYSTuBMeta?.alias || [],
    redirect: _91newsSlug_932cahPYSTuBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: indexFjYGdysF1cMeta?.name ?? "news___de",
    path: indexFjYGdysF1cMeta?.path ?? "/de/nachrichten",
    meta: indexFjYGdysF1cMeta || {},
    alias: indexFjYGdysF1cMeta?.alias || [],
    redirect: indexFjYGdysF1cMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexFjYGdysF1cMeta?.name ?? "news___en___default",
    path: indexFjYGdysF1cMeta?.path ?? "/news/",
    meta: indexFjYGdysF1cMeta || {},
    alias: indexFjYGdysF1cMeta?.alias || [],
    redirect: indexFjYGdysF1cMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexFjYGdysF1cMeta?.name ?? "news___en",
    path: indexFjYGdysF1cMeta?.path ?? "/en/news",
    meta: indexFjYGdysF1cMeta || {},
    alias: indexFjYGdysF1cMeta?.alias || [],
    redirect: indexFjYGdysF1cMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexFjYGdysF1cMeta?.name ?? "news___it",
    path: indexFjYGdysF1cMeta?.path ?? "/it/notizie",
    meta: indexFjYGdysF1cMeta || {},
    alias: indexFjYGdysF1cMeta?.alias || [],
    redirect: indexFjYGdysF1cMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexFjYGdysF1cMeta?.name ?? "news___zh",
    path: indexFjYGdysF1cMeta?.path ?? "/zh/xinwen",
    meta: indexFjYGdysF1cMeta || {},
    alias: indexFjYGdysF1cMeta?.alias || [],
    redirect: indexFjYGdysF1cMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: successHdcux76FLCMeta?.name ?? "pardot-success___de",
    path: successHdcux76FLCMeta?.path ?? "/de/pardot/success",
    meta: successHdcux76FLCMeta || {},
    alias: successHdcux76FLCMeta?.alias || [],
    redirect: successHdcux76FLCMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successHdcux76FLCMeta?.name ?? "pardot-success___en___default",
    path: successHdcux76FLCMeta?.path ?? "/pardot/success",
    meta: successHdcux76FLCMeta || {},
    alias: successHdcux76FLCMeta?.alias || [],
    redirect: successHdcux76FLCMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successHdcux76FLCMeta?.name ?? "pardot-success___en",
    path: successHdcux76FLCMeta?.path ?? "/en/pardot/success",
    meta: successHdcux76FLCMeta || {},
    alias: successHdcux76FLCMeta?.alias || [],
    redirect: successHdcux76FLCMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successHdcux76FLCMeta?.name ?? "pardot-success___it",
    path: successHdcux76FLCMeta?.path ?? "/it/pardot/success",
    meta: successHdcux76FLCMeta || {},
    alias: successHdcux76FLCMeta?.alias || [],
    redirect: successHdcux76FLCMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successHdcux76FLCMeta?.name ?? "pardot-success___zh",
    path: successHdcux76FLCMeta?.path ?? "/zh/pardot/success",
    meta: successHdcux76FLCMeta || {},
    alias: successHdcux76FLCMeta?.alias || [],
    redirect: successHdcux76FLCMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5opOjD8sqjMeta?.name ?? "privacy-policy___de",
    path: privacy_45policy5opOjD8sqjMeta?.path ?? "/de/privacy-policy",
    meta: privacy_45policy5opOjD8sqjMeta || {},
    alias: privacy_45policy5opOjD8sqjMeta?.alias || [],
    redirect: privacy_45policy5opOjD8sqjMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5opOjD8sqjMeta?.name ?? "privacy-policy___en___default",
    path: privacy_45policy5opOjD8sqjMeta?.path ?? "/privacy-policy/",
    meta: privacy_45policy5opOjD8sqjMeta || {},
    alias: privacy_45policy5opOjD8sqjMeta?.alias || [],
    redirect: privacy_45policy5opOjD8sqjMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5opOjD8sqjMeta?.name ?? "privacy-policy___en",
    path: privacy_45policy5opOjD8sqjMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policy5opOjD8sqjMeta || {},
    alias: privacy_45policy5opOjD8sqjMeta?.alias || [],
    redirect: privacy_45policy5opOjD8sqjMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5opOjD8sqjMeta?.name ?? "privacy-policy___it",
    path: privacy_45policy5opOjD8sqjMeta?.path ?? "/it/privacy-policy",
    meta: privacy_45policy5opOjD8sqjMeta || {},
    alias: privacy_45policy5opOjD8sqjMeta?.alias || [],
    redirect: privacy_45policy5opOjD8sqjMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5opOjD8sqjMeta?.name ?? "privacy-policy___zh",
    path: privacy_45policy5opOjD8sqjMeta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policy5opOjD8sqjMeta || {},
    alias: privacy_45policy5opOjD8sqjMeta?.alias || [],
    redirect: privacy_45policy5opOjD8sqjMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93gGusIpuMccMeta?.name ?? "projects-projectSlug___de",
    path: _91projectSlug_93gGusIpuMccMeta?.path ?? "/de/projekte/:projectSlug",
    meta: _91projectSlug_93gGusIpuMccMeta || {},
    alias: _91projectSlug_93gGusIpuMccMeta?.alias || [],
    redirect: _91projectSlug_93gGusIpuMccMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93gGusIpuMccMeta?.name ?? "projects-projectSlug___en___default",
    path: _91projectSlug_93gGusIpuMccMeta?.path ?? "/projects/:projectSlug",
    meta: _91projectSlug_93gGusIpuMccMeta || {},
    alias: _91projectSlug_93gGusIpuMccMeta?.alias || [],
    redirect: _91projectSlug_93gGusIpuMccMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93gGusIpuMccMeta?.name ?? "projects-projectSlug___en",
    path: _91projectSlug_93gGusIpuMccMeta?.path ?? "/en/projects/:projectSlug",
    meta: _91projectSlug_93gGusIpuMccMeta || {},
    alias: _91projectSlug_93gGusIpuMccMeta?.alias || [],
    redirect: _91projectSlug_93gGusIpuMccMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93gGusIpuMccMeta?.name ?? "projects-projectSlug___it",
    path: _91projectSlug_93gGusIpuMccMeta?.path ?? "/it/progetti/:projectSlug",
    meta: _91projectSlug_93gGusIpuMccMeta || {},
    alias: _91projectSlug_93gGusIpuMccMeta?.alias || [],
    redirect: _91projectSlug_93gGusIpuMccMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93gGusIpuMccMeta?.name ?? "projects-projectSlug___zh",
    path: _91projectSlug_93gGusIpuMccMeta?.path ?? "/zh/xiangmu/:projectSlug",
    meta: _91projectSlug_93gGusIpuMccMeta || {},
    alias: _91projectSlug_93gGusIpuMccMeta?.alias || [],
    redirect: _91projectSlug_93gGusIpuMccMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: indexUcZSuMQNQcMeta?.name ?? "search___de",
    path: indexUcZSuMQNQcMeta?.path ?? "/de/suchen",
    meta: indexUcZSuMQNQcMeta || {},
    alias: indexUcZSuMQNQcMeta?.alias || [],
    redirect: indexUcZSuMQNQcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUcZSuMQNQcMeta?.name ?? "search___en___default",
    path: indexUcZSuMQNQcMeta?.path ?? "/search/",
    meta: indexUcZSuMQNQcMeta || {},
    alias: indexUcZSuMQNQcMeta?.alias || [],
    redirect: indexUcZSuMQNQcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUcZSuMQNQcMeta?.name ?? "search___en",
    path: indexUcZSuMQNQcMeta?.path ?? "/en/search",
    meta: indexUcZSuMQNQcMeta || {},
    alias: indexUcZSuMQNQcMeta?.alias || [],
    redirect: indexUcZSuMQNQcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUcZSuMQNQcMeta?.name ?? "search___it",
    path: indexUcZSuMQNQcMeta?.path ?? "/it/ricerca",
    meta: indexUcZSuMQNQcMeta || {},
    alias: indexUcZSuMQNQcMeta?.alias || [],
    redirect: indexUcZSuMQNQcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUcZSuMQNQcMeta?.name ?? "search___zh",
    path: indexUcZSuMQNQcMeta?.path ?? "/zh/sousuo",
    meta: indexUcZSuMQNQcMeta || {},
    alias: indexUcZSuMQNQcMeta?.alias || [],
    redirect: indexUcZSuMQNQcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexorI57omolOMeta?.name ?? "sectors-sectorSlug___de",
    path: indexorI57omolOMeta?.path ?? "/de/sektoren/:sectorSlug",
    meta: indexorI57omolOMeta || {},
    alias: indexorI57omolOMeta?.alias || [],
    redirect: indexorI57omolOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexorI57omolOMeta?.name ?? "sectors-sectorSlug___en___default",
    path: indexorI57omolOMeta?.path ?? "/sectors/:sectorSlug/",
    meta: indexorI57omolOMeta || {},
    alias: indexorI57omolOMeta?.alias || [],
    redirect: indexorI57omolOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexorI57omolOMeta?.name ?? "sectors-sectorSlug___en",
    path: indexorI57omolOMeta?.path ?? "/en/sectors/:sectorSlug",
    meta: indexorI57omolOMeta || {},
    alias: indexorI57omolOMeta?.alias || [],
    redirect: indexorI57omolOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexorI57omolOMeta?.name ?? "sectors-sectorSlug___it",
    path: indexorI57omolOMeta?.path ?? "/it/settori/:sectorSlug",
    meta: indexorI57omolOMeta || {},
    alias: indexorI57omolOMeta?.alias || [],
    redirect: indexorI57omolOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexorI57omolOMeta?.name ?? "sectors-sectorSlug___zh",
    path: indexorI57omolOMeta?.path ?? "/zh/bumen/:sectorSlug",
    meta: indexorI57omolOMeta || {},
    alias: indexorI57omolOMeta?.alias || [],
    redirect: indexorI57omolOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioNBJIGvvK1yMeta?.name ?? "sectors-sectorSlug-portfolio___de",
    path: portfolioNBJIGvvK1yMeta?.path ?? "/de/sektoren/:sectorSlug/portfolio",
    meta: portfolioNBJIGvvK1yMeta || {},
    alias: portfolioNBJIGvvK1yMeta?.alias || [],
    redirect: portfolioNBJIGvvK1yMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioNBJIGvvK1yMeta?.name ?? "sectors-sectorSlug-portfolio___en___default",
    path: portfolioNBJIGvvK1yMeta?.path ?? "/sectors/:sectorSlug/portfolio",
    meta: portfolioNBJIGvvK1yMeta || {},
    alias: portfolioNBJIGvvK1yMeta?.alias || [],
    redirect: portfolioNBJIGvvK1yMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioNBJIGvvK1yMeta?.name ?? "sectors-sectorSlug-portfolio___en",
    path: portfolioNBJIGvvK1yMeta?.path ?? "/en/sectors/:sectorSlug/portfolio",
    meta: portfolioNBJIGvvK1yMeta || {},
    alias: portfolioNBJIGvvK1yMeta?.alias || [],
    redirect: portfolioNBJIGvvK1yMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioNBJIGvvK1yMeta?.name ?? "sectors-sectorSlug-portfolio___it",
    path: portfolioNBJIGvvK1yMeta?.path ?? "/it/settori/:sectorSlug/portfolio",
    meta: portfolioNBJIGvvK1yMeta || {},
    alias: portfolioNBJIGvvK1yMeta?.alias || [],
    redirect: portfolioNBJIGvvK1yMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioNBJIGvvK1yMeta?.name ?? "sectors-sectorSlug-portfolio___zh",
    path: portfolioNBJIGvvK1yMeta?.path ?? "/zh/bumen/:sectorSlug/wenjian-jia",
    meta: portfolioNBJIGvvK1yMeta || {},
    alias: portfolioNBJIGvvK1yMeta?.alias || [],
    redirect: portfolioNBJIGvvK1yMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93hjQ8kQ8sDPMeta?.name ?? "services-serviceSlug___de",
    path: _91serviceSlug_93hjQ8kQ8sDPMeta?.path ?? "/de/dienstleistungen/:serviceSlug",
    meta: _91serviceSlug_93hjQ8kQ8sDPMeta || {},
    alias: _91serviceSlug_93hjQ8kQ8sDPMeta?.alias || [],
    redirect: _91serviceSlug_93hjQ8kQ8sDPMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93hjQ8kQ8sDPMeta?.name ?? "services-serviceSlug___en___default",
    path: _91serviceSlug_93hjQ8kQ8sDPMeta?.path ?? "/services/:serviceSlug",
    meta: _91serviceSlug_93hjQ8kQ8sDPMeta || {},
    alias: _91serviceSlug_93hjQ8kQ8sDPMeta?.alias || [],
    redirect: _91serviceSlug_93hjQ8kQ8sDPMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93hjQ8kQ8sDPMeta?.name ?? "services-serviceSlug___en",
    path: _91serviceSlug_93hjQ8kQ8sDPMeta?.path ?? "/en/services/:serviceSlug",
    meta: _91serviceSlug_93hjQ8kQ8sDPMeta || {},
    alias: _91serviceSlug_93hjQ8kQ8sDPMeta?.alias || [],
    redirect: _91serviceSlug_93hjQ8kQ8sDPMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93hjQ8kQ8sDPMeta?.name ?? "services-serviceSlug___it",
    path: _91serviceSlug_93hjQ8kQ8sDPMeta?.path ?? "/it/servizi/:serviceSlug",
    meta: _91serviceSlug_93hjQ8kQ8sDPMeta || {},
    alias: _91serviceSlug_93hjQ8kQ8sDPMeta?.alias || [],
    redirect: _91serviceSlug_93hjQ8kQ8sDPMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93hjQ8kQ8sDPMeta?.name ?? "services-serviceSlug___zh",
    path: _91serviceSlug_93hjQ8kQ8sDPMeta?.path ?? "/zh/fuwu/:serviceSlug",
    meta: _91serviceSlug_93hjQ8kQ8sDPMeta || {},
    alias: _91serviceSlug_93hjQ8kQ8sDPMeta?.alias || [],
    redirect: _91serviceSlug_93hjQ8kQ8sDPMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: indexmfJZQoGmz8Meta?.name ?? "whistleblowing___de",
    path: indexmfJZQoGmz8Meta?.path ?? "/de/whistleblowing",
    meta: indexmfJZQoGmz8Meta || {},
    alias: indexmfJZQoGmz8Meta?.alias || [],
    redirect: indexmfJZQoGmz8Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexmfJZQoGmz8Meta?.name ?? "whistleblowing___en___default",
    path: indexmfJZQoGmz8Meta?.path ?? "/whistleblowing/",
    meta: indexmfJZQoGmz8Meta || {},
    alias: indexmfJZQoGmz8Meta?.alias || [],
    redirect: indexmfJZQoGmz8Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexmfJZQoGmz8Meta?.name ?? "whistleblowing___en",
    path: indexmfJZQoGmz8Meta?.path ?? "/en/whistleblowing",
    meta: indexmfJZQoGmz8Meta || {},
    alias: indexmfJZQoGmz8Meta?.alias || [],
    redirect: indexmfJZQoGmz8Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexmfJZQoGmz8Meta?.name ?? "whistleblowing___it",
    path: indexmfJZQoGmz8Meta?.path ?? "/it/whistleblowing",
    meta: indexmfJZQoGmz8Meta || {},
    alias: indexmfJZQoGmz8Meta?.alias || [],
    redirect: indexmfJZQoGmz8Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexmfJZQoGmz8Meta?.name ?? "whistleblowing___zh",
    path: indexmfJZQoGmz8Meta?.path ?? "/zh/whistleblowing",
    meta: indexmfJZQoGmz8Meta || {},
    alias: indexmfJZQoGmz8Meta?.alias || [],
    redirect: indexmfJZQoGmz8Meta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: pininfarina_46quattrolineeQI0myty2PqMeta?.name ?? undefined,
    path: pininfarina_46quattrolineeQI0myty2PqMeta?.path ?? "/sitemap.xml",
    meta: pininfarina_46quattrolineeQI0myty2PqMeta || {},
    alias: pininfarina_46quattrolineeQI0myty2PqMeta?.alias || [],
    redirect: pininfarina_46quattrolineeQI0myty2PqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.quattrolinee.com/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]