<script setup>
import {computed, ref} from 'vue'
import {ENDPOINT_PARDOT_URL} from '~/globals/globals.js'

import {
  verticalLegalEntities,
  legalEntityFullListAll,
  newsletterConfig,
} from '~/globals/forms.js'
import {required, email, sameAs, helpers} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'

const nuxtApp = useNuxtApp()
const bugsnag = nuxtApp.$bugsnag

const config = useRuntimeConfig()

let endpoint = config.public.apiBase + 'settings.json'
const googleRecaptchaKey = config.public.googleRecaptchaKey
const runningEnv = config.public.runningEnv

const parser = ParserUtils()

const {fetchedData} = await useDataStatic(endpoint)

const processedData = computed(() => {
  if (fetchedData.value) {
    return parser.emailSettings(fetchedData.value)
  }
  return null
})

const {$listen} = useNuxtApp()

const modalVisible = ref(false)
const contactForm = ref()

// trick for avoiding autocompletion
const inputTrick = ref('')

const formFieldsConfig = computed(() => newsletterConfig)

const formFields = ref({
  name: '',
  surname: '',
  email: '',
  phone: '',
  company: '',
  vertical: '',
  legal: '',
  message: '',
  fans: '',
  privacy_crm: [],
  privacy_emails: [],
  privacy_events: [],
  recaptchaCompleted: false,
})

const formFieldsNames = computed(() => ({
  name: 'name' + inputTrick.value,
  surname: 'surname' + inputTrick.value,
  email: 'email' + inputTrick.value,
  phone: 'phone' + inputTrick.value,
  company: 'company' + inputTrick.value,
  vertical: 'vertical' + inputTrick.value,
  legal: 'legal' + inputTrick.value,
  message: 'message' + inputTrick.value,
  fans: 'fans' + inputTrick.value,
  privacy_crm: 'privacy_crm',
  privacy_emails: 'privacy_emails',
  privacy_events: 'privacy_events',
}))

const formValidationRules = computed(() => {
  return {
    name: {
      required: helpers.withMessage('The name field is required', (value) =>
        formFieldsConfig?.value.name.required ? value : true
      ),
    },
    surname: {
      required: helpers.withMessage('The surname field is required', (value) =>
        formFieldsConfig?.value.surname.required ? value : true
      ),
    },
    email: {
      email: helpers.withMessage('Invalid email format', email),
      required: helpers.withMessage('The email field is required', (value) =>
        formFieldsConfig?.value.email.required ? value : true
      ),
    },
    phone: {
      required: helpers.withMessage('The phone field is required', (value) =>
        formFieldsConfig?.value.phone.required ? value : true
      ),
    },
    company: {
      required: helpers.withMessage('The company field is required', (value) =>
        formFieldsConfig?.value.company.required ? value : true
      ),
    },
    vertical: {
      required: helpers.withMessage(
        'The interested in field is required',
        (value) =>
          formFieldsConfig?.value.vertical.required
            ? value !== null && value !== undefined && value.length !== 0
            : true
      ),
    },
    legal: {
      required: helpers.withMessage(
        'The legal entity field is required',
        (value) =>
          formFieldsConfig?.value.legal.required
            ? value !== null && value !== undefined && value.length !== 0
            : true
      ),
    },
    message: {
      required: helpers.withMessage('The message field is required', (value) =>
        formFieldsConfig?.value.message.required ? value : true
      ),
    },
    privacy_crm: {
      sameAs: helpers.withMessage(
        'It is necessary to accept the privacy policy in order to proceed',
        sameAs('Yes')
      ),
    },
  }
})

// initialize vuelidate
const v$ = useVuelidate(formValidationRules, formFields)

// Init form fields with form configuration
const initForm = () => {
  // initialize precompiled values
  formFields.value.name = formFieldsConfig.value.name.precompiled
  formFields.value.surname = formFieldsConfig.value.surname.precompiled
  formFields.value.email = formFieldsConfig.value.email.precompiled
  formFields.value.phone = formFieldsConfig.value.phone.precompiled
  formFields.value.company = formFieldsConfig.value.company.precompiled
  formFields.value.vertical = formFieldsConfig.value.vertical.precompiled
  formFields.value.legal = formFieldsConfig.value.legal.precompiled
  formFields.value.message = formFieldsConfig.value.message.precompiled
  formFields.value.fans = formFieldsConfig.value.fans.precompiled

  // privacy and etc. checkboxes
  formFields.value.privacy_crm = 'No'
  formFields.value.privacy_emails = 'No'
  formFields.value.privacy_events = 'No'

  // console.log('sectorSlug: ', sectorSlug)
}

// Update legal entities according to vertical selected
const legalList = computed(() => {
  const list = []

  const legalRawList = verticalLegalEntities[formFields.value.vertical]

  if (legalRawList) {
    legalRawList.forEach((legalAcronym) => {
      const legalNamesList = legalEntityFullListAll[legalAcronym]

      if (legalNamesList) {
        legalNamesList.forEach((name) => {
          const selectObject = {}

          selectObject.value = legalAcronym
          selectObject.label = name

          list.push(selectObject)
        })
      }
    })
  }

  return list
})

onMounted(() => {
  // listening to opening signal
  $listen('modal:newsletter', () => {
    // initialize form
    initForm()
    // open modal
    modalVisible.value = true
    document.body.classList.add('overflow-y-hidden')
  })

  inputTrick.value = '-' + makeId(4)
})

const formSubmitted = ref(false)
const submissionSuccess = ref(null)

// Preprocessing to clean form names
const handleSubmit = async () => {
  // console.log('=============')
  // console.log('Handle submit')
  // console.log('=============')

  const key = document.querySelector(
    '.form--newsletter .g-recaptcha-response'
  ).value

  if (key.length === 0) {
    v$.value.$touch('recaptcha')
    formFields.value.recaptchaCompleted = false
    return
  } else {
    formFields.value.recaptchaCompleted = true
  }

  // check form validation
  v$.value.$validate()

  // console.log('Validation', v$.value)
  if (!v$.value.$error) {
    // console.log('  - validation OK')
    // clean names
    inputTrick.value = ''

    // console.log('CONTACT FORM: ', contactForm)

    // Sending emails
    await handlePostRequest(
      formFields?.value,
      processedData?.value,
      'Info request from Newsletter form',
      key,
      bugsnag,
      runningEnv
    )

    // Disabling checked checkboxes to prevent sending duplicate fields
    let checkboxes = contactForm.value.querySelectorAll(
      "input[type='checkbox']"
    )
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) checkbox.disabled = true
    })

    // submit form
    // setTimeout(() => {
    // console.log('SUBMITTED PARDOT')
    contactForm.value.action = ENDPOINT_PARDOT_URL
    contactForm.value.submit()
    // }, 350)
  }
}

const successCallback = () => {
  console.log('Form submitted successfully')
  submissionSuccess.value = true
}

const errorCallback = () => {
  console.error('Error submitting form')
  submissionSuccess.value = false
}

const verticalRef = ref(null)
const legalRef = ref(null)

function resetLegal() {
  legalRef.value.resetSelect()
}

function resetFormFields() {
  // Reset select fields
  verticalRef.value.resetSelect()
  if (legalRef) legalRef.value.resetSelect()

  // Reset checkbox fields
  let checkboxes = contactForm.value.querySelectorAll("input[type='checkbox']")
  checkboxes.forEach((checkbox) => {
    checkbox.checked = false
  })
}

const closeModal = () => {
  // close modal
  modalVisible.value = false
  document.body.classList.remove('overflow-y-hidden')

  // reset validation status
  resetFormFields()
  v$.value.$reset()

  // reset submitted form status
  formSubmitted.value = false
}
</script>
<template>
  <div class="modal-component" :class="{show: modalVisible}">
    <div
      class="btn btn--close btn--round btn--round-light sensible"
      @click="closeModal">
      <svg class="icon icon--btn-round icon--rotate-180">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div class="modal">
      <div class="modal-body">
        <div class="modal-body-wrapper">
          <h2 class="form-title">{{ $t('request_highlights') }}</h2>
          <transition name="fade">
            <form
              class="form form--newsletter matomo-form--client"
              v-if="!formSubmitted && formFields"
              @submit.prevent="handleSubmit"
              ref="contactForm"
              novalidate>
              <!-- name field -->
              <div class="form-item" v-show="formFieldsConfig.name.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.first_name') }}
                  {{ formFieldsConfig.name.required ? '*' : '' }}</label
                >
                <input
                  type="text"
                  class="form-input"
                  :required="formFieldsConfig.name.required"
                  :name="formFieldsNames.name"
                  v-model="formFields.name"
                  autocomplete="new-name" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.name.required &&
                    v$.name.$errors &&
                    v$.name.$errors[0]
                  ">
                  {{ v$.name.$errors[0].$message }}
                </div>
              </div>
              <!-- surname field -->
              <div
                class="form-item"
                v-show="formFieldsConfig.surname.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.last_name') }}
                  {{ formFieldsConfig.surname.required ? '*' : '' }}</label
                >
                <input
                  type="text"
                  class="form-input"
                  :required="formFieldsConfig.surname.required"
                  :name="formFieldsNames.surname"
                  v-model="formFields.surname"
                  autocomplete="new-surname" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.surname.required &&
                    v$.surname.$errors &&
                    v$.surname.$errors[0]
                  ">
                  {{ v$.surname.$errors[0].$message }}
                </div>
              </div>
              <!-- email field -->
              <div class="form-item" v-show="formFieldsConfig.email.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.email') }}
                  {{ formFieldsConfig.email.required ? '*' : '' }}</label
                >
                <input
                  type="email"
                  class="form-input"
                  :required="formFieldsConfig.email.required"
                  :name="formFieldsNames.email"
                  v-model="formFields.email"
                  autocomplete="new-email"
                  @change="v$.email.$touch" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.email.required &&
                    v$.email.$errors &&
                    v$.email.$errors[0]
                  ">
                  {{ v$.email.$errors[0].$message }}
                </div>
              </div>
              <!-- phone field -->
              <div class="form-item" v-show="formFieldsConfig.phone.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.phone') }}
                  {{ formFieldsConfig.phone.required ? '*' : '' }}</label
                >
                <input
                  type="text"
                  class="form-input"
                  :required="formFieldsConfig.phone.required"
                  :name="formFieldsNames.phone"
                  v-model="formFields.phone"
                  autocomplete="new-phone" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.phone.required &&
                    v$.phone.$errors &&
                    v$.phone.$errors[0]
                  ">
                  {{ v$.phone.$errors[0].$message }}
                </div>
              </div>
              <!-- company field -->
              <div
                class="form-item"
                v-show="formFieldsConfig.company.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.company') }}
                  {{ formFieldsConfig.company.required ? '*' : '' }}</label
                >
                <input
                  type="text"
                  class="form-input"
                  :required="formFieldsConfig.company.required"
                  :name="formFieldsNames.company"
                  v-model="formFields.company"
                  autocomplete="new-company" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.company.required &&
                    v$.company.$errors &&
                    v$.company.$errors[0]
                  ">
                  {{ v$.company.$errors[0].$message }}
                </div>
              </div>
              <!-- vertical field (interested in) -->
              <div
                class="form-item"
                v-show="formFieldsConfig.vertical.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.interested') }}
                  {{ formFieldsConfig.vertical.required ? '*' : '' }}
                </label>
                <SharedBasicCustomSelect
                  ref="verticalRef"
                  :required="formFieldsConfig.vertical.required"
                  :name="formFieldsNames.vertical"
                  :options="formFieldsConfig.vertical.list"
                  :preselected="formFieldsConfig.vertical.precompiled"
                  allLabel="-"
                  @click="resetLegal()"
                  v-model:modelValue="formFields.vertical" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.vertical.required &&
                    v$.vertical.$errors &&
                    v$.vertical.$errors[0]
                  ">
                  {{ v$.vertical.$errors[0].$message }}
                </div>
              </div>
              <!-- legal entity field -->
              <div
                class="form-item"
                v-show="formFieldsConfig.legal.visibility"
                :class="{'form-item--disabled': legalList.length <= 0}">
                <label class="form-label" for=""
                  >{{ $t('register_fields.legal') }}
                  {{ formFieldsConfig.legal.required ? '*' : '' }}
                </label>
                <SharedBasicCustomSelect
                  ref="legalRef"
                  :required="formFieldsConfig.legal.required"
                  :name="formFieldsNames.legal"
                  :options="legalList"
                  :preselected="formFieldsConfig.legal.precompiled"
                  allLabel="-"
                  v-model:modelValue="formFields.legal" />
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.legal.required &&
                    v$.legal.$errors &&
                    v$.legal.$errors[0]
                  ">
                  {{ v$.legal.$errors[0].$message }}
                </div>
              </div>
              <!-- note field -->
              <div
                class="form-item form-item--full"
                v-if="formFieldsConfig.message.visibility">
                <label class="form-label" for=""
                  >{{ $t('register_fields.message') }}
                  {{ formFieldsConfig.message.required ? '*' : '' }}</label
                >
                <textarea
                  :name="formFieldsNames.message"
                  class="form-input"
                  :required="formFieldsConfig.message.required"
                  cols="30"
                  rows="5"
                  v-model="formFields.message"
                  autocomplete="new-message"></textarea>
                <div
                  class="form-error"
                  v-if="
                    formFieldsConfig.message.required &&
                    v$.message.$errors &&
                    v$.message.$errors[0]
                  ">
                  {{ v$.message.$errors[0].$message }}
                </div>
              </div>
              <!-- fans field (always hidden) -->
              <div
                class="form-item form-item--full"
                v-show="formFieldsConfig.fans.visibility">
                <input
                  type="text"
                  :name="formFieldsNames.fans"
                  v-model="formFields.fans" />
              </div>
              <!-- privacy fields -->
              <div class="form-item form-item--full">
                <SharedBasicCustomCheckbox
                  :label="
                    '*' +
                    $t('privacy.crm1') +
                    ' ' +
                    $t('privacy.privacy_policy') +
                    ' ' +
                    $t('privacy.crm2')
                  "
                  name="policy_crm"
                  :required="true"
                  v-model:modelValue="formFields.privacy_crm" />
                <div
                  class="form-error"
                  v-if="v$.privacy_crm.$errors && v$.privacy_crm.$errors[0]">
                  {{ v$.privacy_crm.$errors[0].$message }}
                </div>
              </div>
              <div class="form-item form-item--full">
                <SharedBasicCustomCheckbox
                  :label="$t('privacy.emails')"
                  name="policy_emails"
                  :required="false"
                  v-model:modelValue="formFields.privacy_emails" />
              </div>
              <div class="form-item form-item--full">
                <SharedBasicCustomCheckbox
                  :label="$t('privacy.updates')"
                  name="policy_events"
                  :required="false"
                  v-model:modelValue="formFields.privacy_events" />
              </div>
              <!-- Pardot extra field -->
              <div style="position: absolute; left: -9999px; top: -9999px">
                <label for="pardot_extra_field">Comments</label>
                <input
                  type="text"
                  id="pardot_extra_field"
                  name="pardot_extra_field" />
              </div>
              <!-- Google recaptcha -->
              <div class="form-item">
                <div
                  class="g-recaptcha"
                  :data-sitekey="googleRecaptchaKey"></div>
                <div
                  class="form-error"
                  v-if="v$.$dirty && !formFields.recaptchaCompleted">
                  Please flag the reCAPTCHA
                </div>
              </div>
              <div class="form-item form-item--full form-item-actions">
                <button
                  class="btn btn--primary-dark matomo-contact-form sensible">
                  {{ $t('send_request') }}
                </button>
              </div>
            </form>
          </transition>
          <transition name="fade">
            <div
              class="submission submission--ok"
              v-if="formSubmitted && submissionSuccess">
              {{ $t('form_submission_ok') }}
            </div>
          </transition>
          <transition name="fade">
            <div
              class="submission submission--ko"
              v-if="formSubmitted && !submissionSuccess">
              {{ $t('form_submission_ko') }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modal-component {
  align-items: center;
  background-color: rgba($color-blue, 0.95);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity $transition-default;

  &.show {
    opacity: 1;
    pointer-events: all;
    z-index: 7;
    transition: opacity $transition-default;
  }

  .btn--close {
    position: absolute;
    right: pxrem(20);
    top: pxrem(20);
    background-color: rgba($color-blue, 0.2);
  }

  .modal {
    background-color: $color-white;
    margin-bottom: 15vh;
    margin-top: 15vh;
    height: 80vh;
    height: 80dvh;
    width: col-width(22);

    @include mq(md) {
      width: col-width(20);
    }

    @include mq(lg) {
      width: col-width(18);
    }
  }

  .modal-header {
  }

  .modal-body {
  }

  .modal-body-wrapper {
    @include scrollbar;
    height: 80vh;
    overflow-y: auto;
    padding: pxrem(25);
    text-align: start;

    @include mq(md) {
      padding: pxrem(50);
    }
  }

  .form-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(30);

    @include mq(md) {
      margin-bottom: pxrem(45);
    }
  }

  .form {
    column-gap: pxrem(15);
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: pxrem(30);

    @include mq(sm) {
      column-gap: pxrem(20);
      row-gap: pxrem(40);
    }
  }

  .form-item {
    grid-column: span 2;

    @include mq(sm) {
      grid-column: span 1;
    }

    &.form-item--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .form-item--full {
    grid-column: span 2;
  }

  .form-label {
    @include paragraph;
    display: block;
    margin-bottom: pxrem(10);
  }

  .form-input {
    @include field-reset;
    @include paragraph;
    border: 1px solid $color-gray-blue;
    border-radius: 5px;
    cursor: none;
    padding: pxrem(12) pxrem(35) pxrem(12) pxrem(15);
    transition: all $transition-default;
    user-select: none;
    width: 100%;

    &.error {
      border-color: $color-validation-error;
    }
  }

  .form-error {
    color: $color-validation-error;
    font-size: $font-small;
    font-weight: 400;
    margin-top: pxrem(5);
  }

  .form-item-actions {
    text-align: center;
  }

  .submission {
    @include display-3;

    &.submission--ok {
      color: $color-validation-success;
    }

    &.submission--ko {
      color: $color-validation-error;
    }
  }
}
</style>
